.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
    display: grid;
    grid-template-rows: 1fr 150px;
    justify-items: center;
    align-items: center;
    background: url(../../images/border2.svg) top left/10vw;
}

.bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    left: 0;
    top: 0;
    z-index: 10;
    font-family: UseFont;
    touch-action: none;
    color: #FFF;
}

.ubox {
    margin: 10px;
    justify-self: stretch;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img>img {
    display: block;
    background: rgba(255,255,255,0.5);
}

.btn {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-family: UseFont;
    color: #333;
    font-size: 12px;
}

.btns {
    height: 150px;
}

.share {
    background: no-repeat center/100% url(../../images/new/btn2.png);
    display: block;
    width: 60vw;
    height: 13vw;
}

.addBtn {
    background: no-repeat center/100% url(../../images/new/btn1.png);
    display: block;
    width: 60vw;
    height: 13vw;
    margin-bottom: 5px;
    margin-bottom: 10px;
}