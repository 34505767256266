.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
    background: no-repeat center/cover url(../../images/topic/bg.png);
}

.page_c {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    padding: 8vw 6vw;
}

.title {
    font-family: UseFont;
    color: #FFF;
    font-size: 5vw;
}

.iicon {
    background: no-repeat center/100% url(../../images/welcome/xiahua.png);
    width: 4vw;
    height: 4vw;
    position: absolute;
    bottom: 2vw;
    left: 48vw;
}

.welcome {
    font-family: UseFont;
    color: #FFF;
    font-size: 8vw;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.showImage {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.question {
    background: no-repeat top/100% url(../../images/topic/topic.png);
    width: 45vw;
    height: 70vw;
    right: 12vw;
    top: 5vw;
    position: absolute;
    box-sizing: border-box;
    padding: 5.4vw 9vw 18vw;
    font-size: 3.8vw;
    font-family: UseFont;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
}

.answers {
    background: rgba(255, 255, 255, 0.9);
    width: 74vw;
    padding: 20vw 0 0;
    box-sizing: border-box;
    top: 71vw;
    left: 13vw;
    position: absolute;
    border-radius: 10vw;
    font-size: 4vw;
    font-family: UseFont;
}

.answers::before {
    width: 100%;
    height: 25vw;
    background: no-repeat top left/18vw url(../../images/topic/group-1.png), url(../../images/topic/group-2.png) 30% 80%/55vw no-repeat, url(../../images/topic/group-3.png) 80% 40%/15vw no-repeat;
    content: "";
    display: block;
    position: absolute;
    top: -5vw;
    left: -4vw;
}

.answers>.answer {
    height: 15vw;
    border-top: 1px solid #B3B3B3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.answers>.answer:first-child {
    border-top: none;
}