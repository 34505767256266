.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
}

.ibeijing {
    background: no-repeat left -10vw/100% url(../../images/welcome/beijing.png);
    position: absolute;
    width: 100%;
    height: 100vw;
}

.ibiaoti {
    background: no-repeat center/100% url(../../images/welcome/message.png);
    width: 73.74vw;
    height: 29.63vw;
    left: 15vw;
    top: 73vw;
    position: absolute;
    box-sizing: border-box;
    padding: 5.4vw 11vw 6vw;
    font-size: 5vw;
    line-height: 1.3;
}

.ibiaoti::before {
    content: "欢迎入学！\A小西带你逛逛西湖大学吧";
    white-space: pre;
    font-family: UseFont;
}

.ixiahua {
    background: no-repeat center/100% url(../../images/welcome/xiahua.png);
    width: 6.25vw;
    height: 5.44vw;
    left: 46.875vw;
    bottom: 10vw;
    position: absolute;
}

.ixiahua2 {
    /* background: no-repeat center/100% url(../../images/welcome/xiahua2.png); */
    width: 26vw;
    height: 5.17vw;
    left: 37vw;
    bottom: 3vw;
    position: absolute;
    text-align: center;
}

.ixiahua2:before {
    font-size: 4.6vw;
    font-family: UseFont;
    color: #FFF;
    content: "我们继续吧";
}

.iyun1 {
    background: no-repeat center/100% url(../../images/welcome/yun1.png);
    width: 25.01vw;
    height: 5.98vw;
    position: absolute;
    top: 3vw;
    left: 6vw;
}

.iyun2 {
    background: no-repeat center/100% url(../../images/welcome/yun2.png);
    width: 19.30vw;
    height: 5.98vw;
    position: absolute;
    top: 6vw;
    left: 12vw;
    z-index: 2;
}

.iyun3 {
    background: no-repeat center/100% url(../../images/welcome/yun3.png);
    width: 19.30vw;
    height: 5.71vw;
    position: absolute;
    top: 17vw;
    left: 80vw;
}

.iyun4 {
    background: no-repeat center/100% url(../../images/welcome/yun4.png);
    width: 19.57vw;
    height: 4.89vw;
    position: absolute;
    top: 12vw;
    left: 70vw;
}

.iyun5 {
    background: no-repeat center/100% url(../../images/welcome/yun5.png);
    width: 25.01vw;
    height: 5.44vw;
    position: absolute;
    top: 5vw;
    left: 50vw;
}

.ixiaoxi1 {
    width: 22vw;
    height: 42vw;
    left: 20vw;
    top: 108vw;
    position: absolute;
}

.ixiaoxi1:before {
    content: "";
    width: 22vw;
    height: 5vw;
    display: block;
    background: #37528A;
    position: absolute;
    border-radius: 50%;
    bottom: 0;
}

.ixiaoxi1:after {
    content: "";
    background: no-repeat center/100% url(../../images/selectUser/xiaoxi1.png);
    width: 22vw;
    height: 45vw;
    display: block;
    position: absolute;
}

.ixiaoxi2 {
    width: 16vw;
    height: 30vw;
    right: 15vw;
    top: 98vw;
    position: absolute;
}

.ixiaoxi2:before {
    content: "";
    width: 100%;
    height: 5vw;
    display: block;
    background: #37528A;
    position: absolute;
    border-radius: 50%;
    bottom: 0;
}

.ixiaoxi2:after {
    content: "";
    background: no-repeat center/100% url(../../images/selectUser/xiaoxi1.png);
    width: 100%;
    height: 30vw;
    display: block;
    position: absolute;
}

.male:after {
    background-image: url(../../images/selectUser/xiaoxi2.png);
}