.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
}

.idengguang {
    background: no-repeat top/100% url(../../images/selectUser/dengguang.png);
    width: 45vw;
    height: 140vw;
    top: 0vw;
    left: 0vw;
    position: absolute;
    transform-origin: top center;
}

.iicon {
    background: no-repeat center/100% url(../../images/selectUser/button1.png);
    width: 35.89vw;
    height: 13.87vw;
    left: 32vw;
    bottom: 10vw;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iicon:before {
    font-size: 5vw;
    font-family: UseFont;
    content: "获取微信信息";
}


.iwenzi {
    /* background: no-repeat center/100% url(../../images/selectUser/wenzi.png); */
    width: 50vw;
    height: 8vw;
    left: 25vw;
    top: 28vw;
    text-align: center;
    position: absolute;
}

.iwenzi:before { 
    font-size: 6vw;
    font-family: UseFont;
    content: "请选择性别";
}

.ixiaoxi1 {
    background: no-repeat center/100% url(../../images/selectUser/xiaoxi1.png);
    width: 26vw;
    height: 48.39vw;
    left: 15vw;
    top: 80vw;
    position: absolute;
    user-select: none;
    -webkit-user-select: none;
    touch-action: none;
}

.ixiaoxi2 {
    background: no-repeat center/100% url(../../images/selectUser/xiaoxi2.png);
    width: 26vw;
    height: 48.67vw;
    right: 14vw;
    top: 80vw;
    position: absolute;
    user-select: none;
    -webkit-user-select: none;
    touch-action: none;
}