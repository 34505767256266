.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
}

.info {
    background: no-repeat left center/6.1vw url(../../images/acadmy/zuo.png), no-repeat right center/6.1vw url(../../images/acadmy/you.png);
    font-family: UseFont;
    width: 46vw;
    height: 10vw;
    line-height: 10vw;
    left: 27vw;
    bottom: 13vw;
    text-align: center; 
    font-size: 4.5vw;
    position: absolute;
    color: #FFF;
}

.academyInfo {
    background: rgba(255, 255, 255, 0.9);
    width: 74vw;
    padding: 5vw 5vw;
    box-sizing: border-box;
    border-radius: 5vw;
    font-size: 5vw;
    font-family: UseFont;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
}

.academyInfo::before{
    content: "";
    display: block;
    background: no-repeat top left/100% url(../../images/acadmy/xiaoren.png);
    width: 18vw;
    height: 25vw;
    position: absolute;
    top: -20vw;
    left: 50%;
    transform: translateX(-50%);
}

.bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    left: 0;
    top: 0;
    z-index: 10;
    touch-action: none;
}

.button {
    background: no-repeat center/100% url(../../images/acadmy/anniu1.png);
    width: 40vw;
    height: 12vw;
    position: absolute;
    bottom: 15vw;
    left: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: UseFont;
    animation: downIn 500ms;
}

.card {
    width: 78vw;
    height: 59vw;
    border-radius: 2vw;
    top: 50vw;
    left: 6vw;
    box-sizing: border-box;
    padding-top: 51.5vw;
    font-family: UseFont;
    text-align: center;
    margin: 8px;
}

.card:before {
    font-size: 4vw;
}

.a1bgn {
    background: no-repeat bottom/cover url(../../images/acadmy/γ_new_1.png);
    width: 153.609vh;
    height: 100vh;
}

.a1bg {
    background: no-repeat bottom/cover url(../../images/acadmy/neijing-a.png);
    width: 245.098vh;
    height: 100vh;
}

.b1bgn {
    background: no-repeat bottom/cover url(../../images/acadmy/δ_new_2.png);
    width: 170.02vh;
    height: 100vh;
}

.b1bg {
    background: no-repeat bottom/cover url(../../images/acadmy/neijing-b.png);
    width: 179.5332vh;
    height: 100vh;
}

.y1bgn {
    background: no-repeat bottom/cover url(../../images/acadmy/β_new_4.png);
    width: 180.505vh;
    height: 100vh;
}

.y1bg {
    background: no-repeat bottom/cover url(../../images/acadmy/neijing-y.png);
    width: 170.5029838vh;
    height: 100vh;
}

.g1bg {
    background: no-repeat bottom/cover url(../../images/acadmy/neijing-g.png);
    width: 203.0456vh;
    height: 100vh;
}

.g1bgn {
    background: no-repeat bottom/cover url(../../images/acadmy/α_new_3.png);
    width: 178.890vh;
    height: 100vh;
}

@keyframes downIn {
    from { transform: translateY(100%); }
}