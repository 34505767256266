.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
    background: url(../../images/border.svg) top left/8.3vw;
}

.map {
    width: 1000px;
    height: 1000px;
    position: absolute;
    left: 0;
    top: 0;
}

.iwenzi {
    /* background: no-repeat center/100% url(../../images/selectUser/wenzi.png); */
    width: 50vw;
    height: 8vw;
    left: 25vw;
    top: 14vw;
    text-align: center;
    position: absolute;
}

.iwenzi:before { 
    font-size: 5vw;
    font-family: UseFont;
    content: " 可以放大查看哦";
}

.button {
    background: no-repeat center/100% url(../../images/acadmy/anniu1.png);
    width: 40vw;
    height: 12vw;
    position: absolute;
    bottom: 15vw;
    left: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: UseFont;
    animation: downInx 500ms;
}

.image {
    width: 1000px;
    height: 1000px;
    position: absolute;
    left: 0;
    top: 0;
}

.moveu {
    width: 24px;
    height: 38.4px;
    position: absolute;
    background: no-repeat top left/100% url(../../images/map/move.png);
    left: -12px;
    top: -30px;
}

.bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    z-index: 10;
}

.endinfo {
    background: no-repeat top/100% url(../../images/acadmy/a-show.png);
    width: 100vw;
    height: 102vw;
    position: absolute;
    left: 0;
    top: 50vw;
    animation: downIn 300ms linear;
}

@keyframes downInx {
    from { transform: translateY(100%); }
}

@keyframes downIn {
    from { transform: translateY(-10%); opacity: 0.5; }
}

@keyframes HideIn {
    from { opacity: 0; }
    50% { transform: translateY(-5%); opacity: 0;  }
}

.message {
    background: no-repeat center/100% url(../../images/welcome/message.png);
    width: 73.74vw;
    height: 29.63vw;
    left: 12vw;
    box-sizing: border-box;
    padding: 5.4vw 11vw 6vw;
    text-align: center;
    font-size: 4.5vw;
    font-family: UseFont;
    position: absolute;
    right: 0;
    top: 20vw;
    z-index: 2;
    animation: HideIn 860ms linear;
}

.message::before{
    content: "";
    display: block;
    background: no-repeat top left/100% url(../../images/acadmy/xiaoren.png);
    width: 15vw;
    height: 20vw;
    position: absolute;
    top: -14vw;
    right: 12vw;
}


.academyInfo {
    background: rgba(255, 255, 255, 0.9);
    width: 74vw;
    padding: 5vw 5vw;
    box-sizing: border-box;
    border-radius: 5vw;
    font-size: 4vw;
    font-family: UseFont;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
}

.academyInfo::before{
    content: "";
    display: block;
    background: no-repeat top left/100% url(../../images/acadmy/xiaoren.png);
    width: 18vw;
    height: 25vw;
    position: absolute;
    top: -20vw;
    left: 50%;
    transform: translateX(-50%);
}

.title {
    font-size: 6vw;
    line-height: 1.5;
    margin: 0;
}