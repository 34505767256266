.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
    background: url(../../images/border.svg) top left/8.3vw;
}

.message {
    background: no-repeat center/100% url(../../images/welcome/message.png);
    width: 73.74vw;
    height: 29.63vw;
    left: 12vw;
    top: 5vw;
    position: absolute;
    box-sizing: border-box;
    padding: 5.4vw 11vw 6vw;
    text-align: center;
    line-height: 13vw;
    ;
    font-size: 5.5vw;
}

.message::before {
    content: "本科生书院";
    white-space: pre;
    font-family: UseFont;
}

.info {
    font-family: UseFont;
    width: 80vw;
    left: 10vw;
    top: 33vw;
    position: absolute;
    text-align: center;
    font-size: 5.5vw;
}

.info > div {
    font-size: 4vw;
}

.card {
    width: 60vw;
    height: 100vw;
    border-radius: 2vw;
    position: absolute;
    top: 50vw;
    left: 20vw;
    box-sizing: border-box;
    padding-top: 75vw;
    font-family: UseFont;
    text-align: center;
    transform-origin: 50% 100%;
}

.iicon {
    background: no-repeat center/100% url(../../images/history/icon.png);
    width: 4vw;
    height: 4vw;
    position: absolute;
    bottom: 2vw;
    left: 48vw;
}

.card:before {
    font-size: 7vw;
    display: block;
    margin-bottom: 2vw;
}

.card:after {
    font-size: 4vw;
}

.p1 {
    background: #BDD4E3 no-repeat top/100% url(../../images/acadmy/p1.png);
}

.p1:before {
    content: "α书院";
}

.p1:after {
    content: "现代与抽象";
}

.p2 {
    background: #F4E1DE no-repeat top/100% url(../../images/acadmy/p2.png);
}

.p2:before {
    content: "β书院";
}

.p2:after {
    content: "自然与生命力";
}

.p3 {
    background: #ECDDB3 no-repeat top/100% url(../../images/acadmy/p3.png);
}

.p3:before {
    content: "γ书院";
}

.p3:after {
    content: "古典与艺术";
}

.p4 {
    background: #E7F0F9 no-repeat top/100% url(../../images/acadmy/p4.png);
}

.p4:before {
    content: "δ书院";
}

.p4:after {
    content: "浪漫与戏剧";
}