.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
}

.ibiaoti {
    background: no-repeat center/100% url(../../images/new/start-text.png);
    width: 69.60vw;
    height: 27.19vw;
    top: 60vw;
    left: 15.2vw;
    font-size: 11vw;
    font-family: UseFont;
    text-align: center;
    color: #1A4482;
    position: absolute;
}

.ixuexiao {
    background: no-repeat center/100% url(../../images/new/start-end.png);
    width: 58vw;
    height: 10vw;
    position: absolute;
    bottom: 20vw;
    left: 21vw;
    font-size: 16px;
    font-family: UseFont;
    text-align: center;
    color: #1A4482;
}

.iyun1 {
    background: no-repeat center/100% url(../../images/home/yun1.png);
    width: 62.53vw;
    height: 41.60vw;
    position: absolute;
    left: -10vw;
    top: 30vw;
}

.iyun2 {
    background: no-repeat center/100% url(../../images/home/yun2.png);
    width: 54.65vw;
    height: 26.64vw;
    position: absolute;
    right: -10vw;
    top: 120vw;
}

.iyun3 {
    background: no-repeat center/100% url(../../images/home/yun3.png);
    width: 42.96vw;
    height: 19.85vw;
    position: absolute;
    right: -10vw;
    top: 4vw;
}

.iyun4 {
    background: no-repeat center/100% url(../../images/home/yun4.png);
    width: 55.19vw;
    height: 29.09vw;
    position: absolute;
    right: -10vw;
    top: 60vw;
}

.iyun5 {
    background: no-repeat center/100% url(../../images/home/yun5.png);
    width: 88.90vw;
    height: 41.05vw;
    position: absolute;
    right: 5vw;
    top: 70vw;
}

.iyun6 {
    background: no-repeat center/100% url(../../images/home/yun6.png);
    width: 51.66vw;
    height: 23.65vw;
    position: absolute;
    top: 150vw;
    left: -10vw;
}