.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
    background: url(../../images/border.svg) top left/8.3vw;
}

.i2016 {
    background: no-repeat center/100% url(../../images/history/2016.png);
    width: 45vw;
    height: 36.43vw;
    position: absolute;
    top: 4vw;
    left: 8vw;
}

.i2017 {
    background: no-repeat center/100% url(../../images/history/2017.png);
    width: 38.06vw;
    height: 33vw;
    position: absolute;
    top: 33vw;
    right: 10vw;
}

.i2018 {
    background: no-repeat center/100% url(../../images/history/2018.png);
    width: 37.52vw;
    height: 30.99vw;
    position: absolute;
    top: 65vw;
    left: 10vw;
}

.i2019 {
    background: no-repeat center/100% url(../../images/history/2021.png);
    width: 41.87vw;
    height: 28.27vw;
    position: absolute;
    top: 100vw;
    right: 15vw;
}

.i2022 {
    background: no-repeat center/100% url(../../images/history/2022.png);
    width: 41.87vw;
    height: 28.27vw;
    position: absolute;
    top: 134vw;
    left: 10vw;
}

.iicon {
    background: no-repeat center/100% url(../../images/history/icon.png);
    width: 4vw;
    height: 4vw;
    position: absolute;
    bottom: 5vw;
    left: 48vw;
}

.ixiahuatishi {
    width: 40vw;
    height: 13.13vw;
    position: absolute;
    bottom: 8vw;
    left: 30vw;
    font-family: UseFont;
    text-align: center;
    line-height: 13.13vw;
    font-size: 18px;
}

.ixiahuatishi:before {
    content: "开启入学之旅";
}

.ixian1 {
    background: no-repeat center/100% url(../../images/history/xian1.png);
    width: 20.39vw;
    height: 13.87vw;
    position: absolute;
    top: 42vw;
    left: 22vw;
}

.ixian2 {
    background: no-repeat center/100% url(../../images/history/xian2.png);
    width: 20.39vw;
    height: 22.57vw;
    position: absolute;
    top: 68vw;
    right: 25vw;
}

.ixian3 {
    background: no-repeat center/100% url(../../images/history/xian3.png);
    width: 17.94vw;
    height: 19.57vw;
    position: absolute;
    top: 97vw;
    left: 22vw;
}

.ixian4 {
    background: no-repeat center/100% url(../../images/history/xian4.png);
    width: 17.94vw;
    height: 19.57vw;
    position: absolute;
    top: 128vw;
    right: 30vw;
}

.iyanjing {
    background: no-repeat center/100% url(../../images/history/yanjing.png);
    width: 19.30vw;
    height: 11.69vw;
    position: absolute;
    top: 7vw;
    left: 8vw;
}