.page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
    display: flex;
    flex-flow: column;
    align-items: center;
   transform-origin: 50% 20%;
}

.info {
    font-family: UseFont;
    width: 80vw;
    left: 10vw;
    padding-top: 5vw;
    text-align: center;
    line-height: 13vw;
    font-size: 5.5vw;
    animation: upIn 500ms;
}

.button {
    background: no-repeat center/100% url(../../images/acadmy/anniu2.png);
    width: 40vw;
    height: 12vw;
    position: absolute;
    bottom: 15vw;
    left: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: UseFont;
    animation: downIn 500ms;
}

.card {
    width: 78vw;
    height: 59vw;
    border-radius: 2vw;
    top: 50vw;
    left: 6vw;
    box-sizing: border-box;
    padding-top: 51.5vw;
    font-family: UseFont;
    text-align: center;
    margin: 8px;
}

.photo {
    background: #FFF;
    width: 100%;
    height: 1000%;
    position: absolute;
    top: 0;
    left: 0;
}

.card:before {
    font-size: 4vw;
}

.a1bg {
    background: no-repeat bottom/100% url(../../images/acadmy/wai1.png);
}

.b1bg {
    background: no-repeat bottom/100% url(../../images/acadmy/wai2.png);
}

.y1bg {
    background: no-repeat bottom/100% url(../../images/acadmy/wai3.png);
}

.g1bg {
    background: no-repeat bottom/100% url(../../images/acadmy/wai4.png);
}

.user {
    background: no-repeat center/100% url(../../images/selectUser/xiaoxi1.png);
    width: 22vw;
    height: 40vw;
    position: absolute;
    user-select: none;
    -webkit-user-select: none;
    touch-action: none;
    animation: grow 500ms;
    transform-origin: 50% 100%;
}

.a1bg .user {
    left: 18vw;
    bottom: 30vw;
}

.b1bg .user {
    left: 18vw;
    bottom: 32vw;
}

.y1bg .user {
    right: 10vw;
    bottom: 48vw;
}

.g1bg .user {
    left: 18vw;
    bottom: 35vw;
}

.male {
    background-image: url(../../images/selectUser/xiaoxi2.png);
}

@keyframes grow {
    from { transform: scale(0.8); }
}

@keyframes upIn {
    from { transform: translateY(-100%); }
}

@keyframes downIn {
    from { transform: translateY(100%); }
}